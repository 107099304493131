<template>
<div class="bg-white">
  <div class="mx-auto py-8">
    <div class="mt-6">
      <div class="mx-auto px-4 sm:px-6 md:px-8 space-y-4">
        <h3 class="text-center font-bold">商品検索</h3>
        <div>
          <label>カテゴリ</label>
          <select v-model="pq_params.category1" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in opts.categories1" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>小カテゴリ</label>
          <select v-model="pq_params.category2" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in filterdCategories2" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>キーワード</label>
          <input type="text" v-model="pq_params.keywords"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"/>
        </div>
        <div>
          <label>クリエーター</label>
          <input type="text" v-model="pq_params.name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"/>
        </div>
        <div class="space-y-1">
          <button class="w-full btn btn-sm bg-orange-400 hover:bg-orange-500 text-white" @click="searchProductsHandler">検索</button>
          <button class="w-full btn btn-sm bg-orange-400 hover:bg-orange-500 text-white" @click="searchAllProductsHandler">全商品を見る</button>
        </div>
      </div>

      <div class="pt-8 mx-auto px-4 sm:px-6 md:px-8 space-y-4">
        <h3 class="text-center font-bold">生地検索</h3>
        <div>
          <label>生地色</label>
          <select v-model="cq_params.color" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in opts.colors" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>生地厚</label>
          <select v-model="cq_params.thickness" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in opts.thicknesses" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>生地素材</label>
          <select v-model="cq_params.material" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in opts.materials" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>生地種類</label>
          <select v-model="cq_params.type" class="block w-full max-w-lg mt-1 rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="opt in opts.types" :key="opt.value" :value="opt.value">
              {{ opt.title ? opt.title : opt.value }}
            </option>
          </select>
        </div>
        <div>
          <label>キーワード</label>
          <input type="text" v-model="cq_params.keywords"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"/>
        </div>
        <div class="space-y-1">
          <button class="w-full btn btn-sm bg-orange-400 hover:bg-orange-500 text-white" @click="searchClothesHandler">検索</button>
          <button class="w-full btn btn-sm bg-orange-400 hover:bg-orange-500 text-white" @click="searchAllClothesHandler">全生地を見る</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
const route = useRoute()

// products query params
const pq_params = reactive({
  category1: ('category1' in route.query) ? route.query.category1 : null,
  category2: ('category2' in route.query) ? route.query.category2 : null,
  keywords:  ('keywords'  in route.query) ? route.query.keywords  : null,
  name:      ('name'      in route.query) ? route.query.name      : null,
})

// clothes query params
const cq_params = reactive({
  color:     ('color' in route.query)     ? route.query.color     : null,
  thickness: ('thickness' in route.query) ? route.query.thickness : null,
  material:  ('material' in route.query)  ? route.query.material  : null,
  type:      ('type' in route.query)      ? route.query.type      : null,
  keywords:  ('keywords' in route.query)  ? route.query.keywords  : null,
})

const searchProductsHandler = () => {
  buildQuery('products', pq_params)
}

const searchAllProductsHandler = () => {
  pq_params.category1 = null
  pq_params.category2 = null
  pq_params.keywords = null
  pq_params.name = null
  buildQuery('products', pq_params)
}

const searchClothesHandler = () => {
  buildQuery('clothes', cq_params)
}

const searchAllClothesHandler = () => {
  cq_params.color = null
  cq_params.thickness = null
  cq_params.material = null
  cq_params.type = null
  cq_params.keywords = null
  buildQuery('clothes', cq_params)
}

const buildQuery = (target, query) => {
  const _params = Object.keys(query).filter(key => {
    return (query[key])  
  }).map((key) => `${key}=${query[key]}`)

  const params = Object.assign([], _params)
  navigateTo(`/${target}?${params.join('&')}`, { external: true })
}

const opts = reactive({
  categories1: [],
  categories2: [],
  colors:      [],
  thicknesses: [],
  materials:   [],
  types:       [],
})

const filterdCategories2 = computed(() => {
  return opts.categories2.filter((opt) => {
    return opt.key.startsWith(pq_params.category1) 
  })
})

const runtimeConfig = useRuntimeConfig()

await useAsyncData(`/put/options/categories`, async () => {
  return await Promise.all([
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/product:category1`),
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/product:category2`),
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/cloth:colors`),
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/cloth:thicknesses`),
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/cloth:materials`),
    $fetch(`${runtimeConfig.public.apiBase}/pub/options/cloth:types`),
  ])
}, { initialCache: false })
.then(({ data, error, pending, refresh }) => {
  if (data.value.length > 0) {
    Object.assign(opts.categories1, [{title:'',value:''}].concat(data.value[0].payload))
    Object.assign(opts.categories2, data.value[1].payload)
    Object.assign(opts.colors,      data.value[2].payload)
    Object.assign(opts.thicknesses, data.value[3].payload)
    Object.assign(opts.materials,   data.value[4].payload)
    Object.assign(opts.types,       data.value[5].payload)
  }
})
</script>